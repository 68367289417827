import { redirect } from '@remix-run/node'
import { requireAuthenticated } from '../auth.server'
import { combineHeaders } from '../misc'
import {
	type User,
	type CleaningOrderAdditionalService,
	type CleaningOrderAddress,
	type CleaningOrderEnum,
	type CleaningOrderPaymentType,
	type CleaningOrderRepeatInfo,
	type CleaningOrderStatus,
	type CleaningOrderType,
	type CleaningProvider,
	type CleaningOrder,
	type Settings,
	type CleaningOrderRating,
} from './types'

export type ErrorResponse = {
	errors: { path?: string; message: string; error: string }[]
}

const fileUploadFetch = async <TData>(
	url: string,
	request: Request,
	formData: FormData,
) => {
	const token = await requireAuthenticated(request)
	const response = await fetch(`${ENV.API_URL}/v1${url}`, {
		method: 'PUT',
		headers: { Cookie: 'IzzyKolacik=' + token },
		body: formData,
	})

	const data = (await response.json()) as TData

	if (response.status < 400) {
		console.log('File successfully uploaded', data)
	}

	return { response, data }
}

const baseFetch = async <TData>(
	url: string,
	options: RequestInit = {},
	skipResponseParsing = false,
) => {
	const response = await fetch(`${ENV.API_URL}/v1${url}`, {
		...options,
		headers: combineHeaders(
			{ 'Content-Type': 'application/json' },
			options?.headers,
		),
	})

	if (skipResponseParsing) {
		if (response.status >= 400) {
			console.log(response.status, response.statusText, await response.json())

			return {
				response,
				data: {
					errors: [{ message: 'Server error', error: 'Server error' }],
				},
			}
		}

		return { response, data: undefined as TData }
	}

	let data = (await response.json()) as
		| TData
		| ErrorResponse
		| { statusCode: number; message: string; error: string }

	if (
		data &&
		typeof data === 'object' &&
		!('errors' in data) &&
		'error' in data
	) {
		console.log(data)
		data = { errors: [{ message: data.message, error: data.error }] }
	} else if (response.status === 401) {
		const isBrowser = typeof document !== 'undefined'
		const url = '/logout?reason=expired-session'
		console.log(data)
		if (isBrowser) {
			window.location.href = url
		} else {
			throw redirect(url)
		}
		data = { errors: [{ message: 'Unauthorized', error: 'Unauthorized' }] }
	}

	return { response, data }
}

const authedFetch = async <TData>(
	url: string,
	request: Request,
	options?: RequestInit,
) => {
	const token = await requireAuthenticated(request)
	const response = await baseFetch<TData>(url, {
		...options,
		headers: combineHeaders(
			{ Cookie: 'IzzyKolacik=' + token },
			options?.headers,
		),
	})

	return response
}

export const api = {
	registerCustomer: (body: {
		email: string
		password: string
		name?: string
		surname?: string
		phoneNumber: string
		facebookId?: string
		googleId?: string
		appleId?: string
		companyName?: string
		address?: string
		cin?: string
		dic?: string
	}) =>
		baseFetch<User>(`/auth/register/customer`, {
			method: 'POST',
			body: JSON.stringify(body),
		}),
	registerProvider: (body: {
		email: string
		businessName: string
		cin?: string
		phoneNumber: string
		password: string
	}) =>
		baseFetch<User>(`/auth/register/provider`, {
			method: 'POST',
			body: JSON.stringify(body),
		}),
	login: (body: {
		email: string
		password: string
		facebookId?: string
		googleId?: string
		appleId?: string
	}) =>
		baseFetch<{
			accessToken: string
			user: User
		}>(`/auth/login`, {
			method: 'POST',
			body: JSON.stringify(body),
		}),
	getProfile: (request: Request) =>
		authedFetch<User>(`/personal/profile`, request),
	socialLogin: (body: {
		idToken?: string
		accessToken?: string
		serviceName: string
	}) =>
		baseFetch<{
			accessToken: string
			user: User
		}>(`/auth/login-social`, {
			method: 'POST',
			body: JSON.stringify(body),
		}),
	getProvider: (providerId: string) =>
		baseFetch<CleaningProvider>(`/users/providers/${providerId}`),
	getProviders: (skip = 0, limit = 50, filter = '') =>
		baseFetch<{
			data: CleaningProvider[]
			total: number
			limit: number
			skip: number
		}>(`/users/providers?skip=${skip}&limit=${limit}&${filter}`),

	getProviderImageUrl: (providerId: string) => {
		return `https://izzy-production-data.s3.eu-central-1.amazonaws.com/prod/profile/${providerId}`
	},
	getProviderRatings: (skip = 0, limit = 50, providerId: string) =>
		baseFetch<{
			data: {
				orderId: string
				rating: CleaningOrderRating
				customerName: string
			}[]
			total: number
			limit: number
			skip: number
		}>(`/cleaning-orders/ratings/${providerId}?skip=${skip}&limit=${limit}`),
	getEstimate: (
		objectSize: string,
		cleaningType: string,
		additionalServicesDuration: number,
	) =>
		baseFetch<{
			detergentsPrice: number
			duration: number
			price: number
			expressCleaningPrice: number
			extraCleaningHourPrice?: number
			fixedFee?: number
		}>(
			`/cleaning-orders/estimate?objectSize=${objectSize}&cleaningType=${cleaningType}&additionalServicesDuration=${additionalServicesDuration}`,
		),
	searchAddresses: (query: string) =>
		baseFetch<{ value: string }[]>(`/addresses/search?query=${query}`),
	createOrder: (
		body: {
			address: CleaningOrderAddress
			floor?: string
			date: string
			objectSizeKey: string
			cleaningTypeKey: string
			hasDetergents: boolean
			note?: string
			repeatInfo?: CleaningOrderRepeatInfo
			additionalServices?: CleaningOrderAdditionalService[]
			extraCleaningHours?: number
			promoCode?: string
		},
		request: Request,
	) =>
		authedFetch<{
			order: {
				type: CleaningOrderType
				customerId: string
				address: CleaningOrderAddress
				floor: string
				date: string
				objectSize: CleaningOrderEnum
				cleaningType: CleaningOrderEnum
				hasDetergents: boolean
				note: string
				price: {
					detergents: number
					hourly: number
					duration: number
					basePrice: number
				}
				amount: number
				payments: CleaningOrderPaymentType[]
				status: CleaningOrderStatus
				repeatInfo: CleaningOrderRepeatInfo
				additionalServices: CleaningOrderAdditionalService[]
				extraCleaningHours?: number
				_id: string
				createdAt: string
				updatedAt: string
			}
			payment: {
				customerId: string
				ephemeralKey: string
				setupIntent: string
			}
		}>(`/cleaning-orders`, request, {
			method: 'POST',
			body: JSON.stringify(body),
		}),
	listOrders: (request: Request, type?: string) =>
		authedFetch<{
			data: CleaningOrder[]
			total: number
			limit: number
		}>(
			`/cleaning-orders/created?limit=50${type ? `&filter[type]=${type}` : ''}`,
			request,
		),
	cancelOrder: (orderId: string, request: Request) =>
		authedFetch<{ _id: string; status: CleaningOrderStatus }>(
			`/cleaning-orders/${orderId}/cancel`,
			request,
			{ method: 'PUT' },
		),
	getSettings: () => baseFetch<Settings>(`/settings`),
	verifyRatingToken: (token: string) =>
		baseFetch<{
			token?: string
			communication?: number
			quality?: number
			overall?: number
			average?: number
			comment?: string
			recordedAt?: Date
		}>(`/cleaning-orders/rating/verify-token?token=${token}`),
	rateOrder: (
		token: string,
		body: {
			communication: number
			quality: number
			overall: number
			comment?: string
		},
	) =>
		baseFetch<{
			token?: string
			communication?: number
			quality?: number
			overall?: number
			average?: number
			comment?: string
			recordedAt?: Date
		}>(`/cleaning-orders/ratings?token=${token}`, {
			method: 'POST',
			body: JSON.stringify(body),
		}),
	updateProfile: (
		request: Request,
		body: {
			name?: string
			surname?: string
			phoneNumber?: string
			businessName?: string
			description?: string
		},
	) =>
		authedFetch<User>(`/personal/profile`, request, {
			method: 'PATCH',
			body: JSON.stringify(body),
		}),
	changePassword: (
		request: Request,
		body: {
			oldPassword: string
			newPassword: string
		},
	) =>
		authedFetch<User>(`/auth/change-password`, request, {
			method: 'PUT',
			body: JSON.stringify(body),
		}),
	uploadPhoto: (request: Request, formData: FormData) =>
		fileUploadFetch<{ image: string }>(
			'/personal/profile/image',
			request,
			formData,
		),
	getAdditionalServices: () =>
		baseFetch<{
			data: {
				_id: string
				name: string
				duration: number
			}[]
			total: number
			limit: number
			skip: number
		}>('/additional-services?skip=0&limit=50'),
	forgottenPassword: (body: { email: string }) =>
		baseFetch<undefined>(
			'/auth/forgotten-password',
			{
				method: 'PUT',
				body: JSON.stringify(body),
			},
			true,
		),
	resetPassword: (body: { token: string; password: string }) =>
		baseFetch<undefined>(
			'/auth/reset-password',
			{
				method: 'PUT',
				body: JSON.stringify(body),
			},
			true,
		),
	deleteAccount: (request: Request) =>
		authedFetch<User>('/personal/profile', request, {
			method: 'DELETE',
		}),
	checkPromoCode: (promoCode: string) =>
		baseFetch<{
			code: string
			discountType: 'fixed' | 'percentage'
			discountAmount: number
		} | null>(`/promo-codes/${promoCode}`, {}, true),
}
